// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\work\\stimulatenew\\stimulate\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("D:\\work\\stimulatenew\\stimulate\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("D:\\work\\stimulatenew\\stimulate\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("D:\\work\\stimulatenew\\stimulate\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-process-js": () => import("D:\\work\\stimulatenew\\stimulate\\src\\pages\\process.js" /* webpackChunkName: "component---src-pages-process-js" */)
}

